<template>
    <div class="new-email-container card flex-1">
        <header class="flex justify-between items-center px-4 py-2">
            <p>{{ $t('communication.new_email') }}</p>
            <el-button type="primary" @click="goBack">
                {{ $t('common.go_back') }}
            </el-button>
        </header>
        <header class="flex flex-col border-b border-solid border-t border-gray-300 p-4 pb-0 mb-5">
            <div class="flex justify-between items-center mb-4">
                <p class="w-24 text-sm">
                    {{ $t('communication.to') }}:
                </p>
                <ClientsDropdown
                    ref="clientsDropdown"
                    withoutQuerySearch
                    multiple
                    withArchive
                    class="w-full"
                    :collapseTags="false"
                    @selected="clientSelected"
                />
            </div>
            <div class="flex">
                <p class="w-24 mt-1 text-sm">
                    {{ $t('communication.subject') }}:
                </p>
                <el-form ref="formTitle" :model="emailData" :rules="rules" class="w-full" @submit.native.prevent>
                    <el-form-item prop="title" class="w-full">
                        <el-input v-model="emailData.title" class="w-full" />
                    </el-form-item>
                </el-form>
            </div>
        </header>

        <quill-editor
            ref="myQuillEditor"
            v-model="emailData.content"
            :options="editorOption"
        />

        <div class="flex justify-end mt-4">
            <el-button :disabled="true" type="success" @click="sendEmail">
                {{ $t('communication.send') }}
            </el-button>
        </div>
    </div>
</template>
<script>
// @Filip
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';

export default {
    components: {
        ClientsDropdown: () => import(/* webpackChunkName: "ClientsDropdown" */ '@/components/ClientsDropdown'),
        quillEditor,
    },

    data() {
        return {
            emailData:       {},
            selectedClients: [],
            rules:           {},
            editorOption:    {
                placeholder: ' ',
                debug:       false,
                modules:     {
                    toolbar: [
                        [{ header: 1 }, { header: 2 }],
                        ['bold', 'italic', 'underline'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ align: [] }],
                    ],
                },
            },
        };
    },

    methods: {
        goBack() {
            this.emailData = {};
            this.$emit('goBack');
        },

        async sendEmail() {
            await axios.post(`${process.env.VUE_APP_CORE_API_URL}/communication/emails`, {
            });
        },
    },
};
</script>
<style>
.new-email-container .ql-editor {
    height: 500px;
}
.new-email-container .reset-margin {
    margin-bottom: 0 !important;
}
</style>
